import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { S1, S2, S3, S4 } from '../sections/reklama-dla-biznesu'
import Footer from '../sections/s4.footer.section'


const IndexPage = () => {

  return (
    <Layout>
      <SEO title="RDB Group | Reklama Dla Biznesu" />
      <S1 />
      <S2 />
      <S3 />
      <S4 />
      <Footer />
    </Layout>
  )
}

export default IndexPage
