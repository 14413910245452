import React from 'react'

import { colors, margins, fontSizes, breakpoint } from '../../cms/style'
// import stringEnter from '../../utils/stringEnter.util'
import { section2 } from '../../cms/data/reklama-dla-biznesu/data'

import team1 from "../../assets/services-icons/project-manager-2.png"
import team2 from "../../assets/services-icons/google-kampanie.png"
import team3 from "../../assets/services-icons/video-nagrywanie-2.png"
import team4 from "../../assets/services-icons/social-media.png"
import team5 from "../../assets/services-icons/grafika-outsourcing2.png"
import team6 from "../../assets/services-icons/copywriting.png"





const ServiceItem = ({ data }) => {

  switch (data.imgId) {
    case "team1":
      return (
        <div css={styles.servicesItem}>
          <img src={team1} alt="" />
          <h4>{data.header}</h4>
          <p>{data.text}</p>
        </div>
      )
    case "team2":
      return (
        <div css={styles.servicesItem}>
          <img src={team2} alt="" />
          <h4>{data.header}</h4>
          <p>{data.text}</p>
        </div>
      )
    case "team3":
      return (
        <div css={styles.servicesItem}>
          <img src={team3} alt="" />
          <h4>{data.header}</h4>
          <p>{data.text}</p>
        </div>
      )
    case "team4":
      return (
        <div css={styles.servicesItem}>
          <img src={team4} alt="" />
          <h4>{data.header}</h4>
          <p>{data.text}</p>
        </div>
      )
    case "team5":
      return (
        <div css={styles.servicesItem}>
          <img src={team5} alt="" />
          <h4>{data.header}</h4>
          <p>{data.text}</p>
        </div>
      )
    case "team6":
      return (
        <div css={styles.servicesItem}>
          <img src={team6} alt="" />
          <h4>{data.header}</h4>
          <p>{data.text}</p>
        </div>
      )
    default:
      return <div>nie działa</div>
  }

}

export default function S2() {

  return (
    <section css={styles.mainSection}>
      <div css={styles.info}>
        {section2.headParagraphs.map((el, index) => <p key={index}>{el}</p>)}
      </div>

      <div css={styles.servicesParent}>
        {section2.services.map((el, index) => <ServiceItem key={index} data={el} />)}
      </div>
    </section>
  )
}

const styles = {
  mainSection: {
    padding: margins.sectionsX.xl,

    [breakpoint.lg]: {
      padding: margins.sectionsX.lg,
    },
    [breakpoint.md]: {
      padding: margins.sectionsX.md,
    },
    [breakpoint.sm]: {
      padding: margins.sectionsX.sm,
      paddingRight: 48
    },
    [breakpoint.xs]: {
      padding: margins.sectionsX.xs,
      paddingRight: 48,
    },
  },

  info: {
    width: '100%',
    margin: '0 auto',

    p: {
      fontSize: '1.1rem',
      textAlign: 'center',
      marginBottom: '.5rem',
    },

    [breakpoint.lg]: {
      p: {
        fontSize: fontSizes.fz4.lg
      }
    },
    [breakpoint.md]: {
      width: '70%',

      p: {
        fontSize: fontSizes.fz4.md
      }
    },
    [breakpoint.sm]: {
      width: '100%',

      p: {
        fontSize: fontSizes.fz4.sm
      }
    },
    [breakpoint.xs]: {
      p: {
        fontSize: fontSizes.fz4.sm
      }
    },
  },

  servicesParent: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '64px 0 0'
  },

  servicesItem: {
    width: '33.333%',
    // padding: '32px 7% 32px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '70px',

    img: {
      width: '60%'
    },

    h4: { fontSize: fontSizes.fz4.xl, color: colors.headers.black, marginBottom: 8, marginTop: '1.3rem', textAlign: 'center' },

    p: { fontSize: fontSizes.fz5.xl, textAlign: 'center' },

    [breakpoint.lg]: {
      p: { fontSize: fontSizes.fz5.lg },
      h4: { fontSize: fontSizes.fz4.lg },
    },
    [breakpoint.md]: {
      width: '50%',

      p: { fontSize: fontSizes.fz5.md },
      h4: { fontSize: fontSizes.fz4.md },
    },
    [breakpoint.sm]: {
      width: '100%',
      padding: 0,
      marginBottom: '5rem',
      img: {
        maxWidth: '70wh',
      },
      p: { fontSize: fontSizes.fz5.sm },
      h4: { fontSize: fontSizes.fz4.sm },
    },
    [breakpoint.xs]: {
      p: { fontSize: fontSizes.fz5.xs },
      h4: { fontSize: fontSizes.fz4.xs },
    },
  }
}
